<template>
  <div id="portalList">
    <List
      :columns="columns"
      :items="items"
      :form="form"
      :list="list"
      :process-level="processLevel"
      :form-view.sync="formView"
      :workflow="workflow"
      @actions="handleActions"
      @subactions="handleSubActions"
      @open="handleProcess"
      @opensubprocess="handleSubProcess"
      @viewFile="handleViewFile"
      @update:formView="handleFormViewUpdate"
    >
    </List>
  </div>
</template>

<script>
import List from "./List/List.vue";

export default {
  name: "PortalList",

  components: { List },

  props: {
    columns: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },

    form: {
      type: Object,
      default: () => {},
    },

    list: {
      type: Array,
      default: () => [],
    },

    processLevel: {
      type: Array,
      default: () => [],
    },

    formView: {
      type: Boolean,
      default: false,
    },

    workflow: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    handleActions(tab, id) {
      this.$emit("actions", tab, id);
    },

    handleSubActions(tab, mainProcess, id) {
      this.$emit("subactions", tab, mainProcess, id);
    },

    handleProcess(request) {
      this.$emit("open", request);
    },

    handleSubProcess(request) {
      this.$emit("opensubprocess", request);
    },

    handleViewFile(file) {
      this.$emit("viewFile", file);
    },

    handleFormViewUpdate(value) {
      this.$emit("update:formView", value);
    },
  },
};
</script>

<style lang="scss">
#portalList {
  #list {
    table {
      width: 100%;
      th:not(.icon),
      td:not(.icon) {
        width: 150px;
        .subWorkflowTable {
          th:not(.icon),
          td:not(.icon) {
            width: auto;
          }
        }
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      th.icon,
      td.icon {
        width: 36px;
        white-space: nowrap;
      }

      th:hover,
      td:hover {
        overflow: visible;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
}
</style>
